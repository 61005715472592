import './App.css';
import { Routes, Route
} from "react-router-dom";
import PackPalzLoader from './custom-components/packpalzloader';
// import PackPalzQr from './custom-components/packpalzqr';


function App() {
  return (
    <Routes>
    <Route path="/" element={<PackPalzLoader />} />
    {/* <Route path="/Qr" element={<PackPalzQr />} /> */}
    <Route path="*" element={<PackPalzLoader />} />
    </Routes>
  );
}

export default App;
