/***************************************************************************
 * The contents of this file were generated with Amplify Studio.           *
 * Please refrain from making any modifications to this file.              *
 * Any changes to this file will be overwritten when running amplify pull. *
 **************************************************************************/

/* eslint-disable */
import * as React from "react";
import { getOverrideProps } from "@aws-amplify/ui-react/internal";
import { Image } from "@aws-amplify/ui-react";
import { Rotate } from "../styled-components/image.styled";
import { Grid } from "@mui/material";
import logo from "../logo.png"
import { useNavigate } from "react-router-dom";

export default function PackPalzLoader(props) {
  const { overrides, ...rest } = props;
  const navigate = useNavigate()

  // React.useEffect(() => {
  //   const nextpage = () => {
  //   // window.location.href = ""
  //   }
  //   setTimeout(nextpage,10000);
  // }, [])



  return (
    <Grid container
      // width="1088px"
      maxHeight="912px"
      minHeight="912px"
      overflow="auto"
      // display="flex"
      // // gap="unset"

      // direction="column"
      alignItems="center"
      justifyContent="center"
      // position="relative"
      // padding="0px 0px 0px 0px"
        // backgroundColor="black"
       backgroundColor="#F5A630"
      {...getOverrideProps(overrides, "BankingUauth")}
      {...rest}
    >
{/* <Grid item> */}
      <Rotate>
        {/* <View
        backgroundColor="pink"> */}
      <Image
      // marginTop="150px"
      // backgroundColor="green"
      // backgroundColor="black"
      // className="logo"
        width="438px"
        height="440px"
        // display="block"
        // gap="unset"
        // alignItems="unset"
        // justifyContent="unset"
        // position="relative"
        // top="261px"
        // left="350px"
        // borderRadius="1000px"
        // padding="0px 0px 0px 0px"
        // objectFit="cover"
        src={logo}
        {...getOverrideProps(overrides, "image")}
      ></Image>
      {/* </View> */}
      </Rotate> 
      {/* </Grid> */}

    </Grid>
  );
}
