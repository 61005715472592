import styled, { keyframes } from "styled-components";
import { Image } from "@aws-amplify/ui-react";


// Create the keyframes
const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
`;

// Here we create a component that will rotate everything we pass in over two seconds
export const Rotate = styled.div`
  
  
  animation: ${rotate} 20s linear infinite;
  
  
`;